import { BlobWriter, BlobReader, ZipWriter } from '@zip.js/zip.js'
import { getBlob } from '../../api/api'
import { sumBy } from 'lodash'
import { filestackCdnUrl } from '../filestack'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import { PLACEHOLDERS_FOLDER } from 'lib/constants/file-formats'
import { getPresignedUrl } from 'lib/api/file-urls/file-urls'

export function displayFilesize(filesize: number): string {
  const units = ['B', 'KB', 'MB', 'GB']

  let unitIndex = 0
  let size = filesize

  while (size > 1024) {
    size /= 1024
    unitIndex += 1
  }

  return `${size.toFixed(0)} ${units[unitIndex]}`
}

export interface File {
  name: string
  url: string
}

export function removeParenCountFromFileName(fileName: string): [string, number] {
  const regex = /^(.*)\s\((\d+)\)(\.[^.]+)$/
  const match = fileName.match(regex)

  if (match) {
    const baseName = `${match[1]}${match[3]}` // The part before the parentheses and the extension
    const count = parseInt(match[2], 10) // The number inside the parentheses
    return [baseName, count]
  } else {
    return [fileName, 0]
  }
}

export function renameFileNameDuplicates(files: File[]): File[] {
  const nameOccurrences: { [key: string]: number } = {}

  return files.map((file) => {
    // eslint-disable-next-line prefer-const
    let [baseName, originalCount] = removeParenCountFromFileName(file.name)
    let extension = ''

    const dotIndex = baseName.lastIndexOf('.')
    if (dotIndex !== -1) {
      extension = baseName.slice(dotIndex)
      baseName = baseName.slice(0, dotIndex)
    }

    const count = nameOccurrences[baseName + extension] || originalCount
    nameOccurrences[baseName + extension] = count + 1

    if (count > 0) {
      baseName = `${baseName} (${count})`
    }

    return { ...file, name: `${baseName}${extension}` }
  })
}

export async function generateAndDownloadZip(files: File[], zipName: string, onProgress?: (progress: number) => void) {
  const filesWithUniqueNames = renameFileNameDuplicates(files)
  const zipWriter = new ZipWriter(new BlobWriter('application/zip'))
  const progress = new Array<ProgressEvent>(filesWithUniqueNames.length)
  const onItemProgress = (event: ProgressEvent, idx: number) => {
    progress[idx] = event
    if (!progress.includes(undefined)) {
      onProgress(progress.reduce((acc, { loaded }) => acc + loaded * 100, 0) / sumBy(progress, 'total'))
    }
  }

  await Promise.all(
    filesWithUniqueNames.map(async (file, index) => {
      const fileAsBlob = await getBlob(file.url, (event) => onItemProgress(event, index)).then(
        (response) => response.data,
      )
      await zipWriter.add(file.name, new BlobReader(new Blob([fileAsBlob])))
    }),
  )
  const zipFile = await zipWriter.close()

  downloadZipFile(zipFile, zipName)
}

function downloadZipFile(blob: Blob, name: string) {
  Object.assign(document.createElement('a'), {
    download: `${name}.zip`,
    href: URL.createObjectURL(blob),
  }).click()
}

export function downloadFile(name: string, url: string) {
  const nameWithExtension = name.includes('.') ? name : `${name}.png`
  const isS3Link = url.includes('amazonaws.com')
  const a = document.createElement('a')
  a.download = nameWithExtension
  a.target = '_blank'

  // Downloading S3 links require that we create a blob from the response and then create a URL from the blob
  if (isS3Link) {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob)
        a.href = blobUrl
        a.click()
        URL.revokeObjectURL(blobUrl)
      })
      .catch((error) => {
        console.error('Error downloading the file', error)
      })
  } else {
    a.href = url
    a.click()
  }
  a.remove()
}

export function getFileExtension(name: string) {
  return name?.split('.')?.pop()?.toLowerCase()
}

export function getFileNameWithoutExtension(name: string) {
  const parts = name?.split('.')
  if (!parts || parts.length <= 1) {
    return name || ''
  }
  return parts.slice(0, -1).join('.')
}

export function getDownloadUrl(file: TicketFile) {
  return file?.downloadUrl
}

export async function getShareLinkUrl(file: TicketFile, previewUrl?: string) {
  const url = previewUrl || file?.previewUrl

  if (file?.s3Filename) {
    return await getPresignedUrl(file?.s3Filename, 60 * 60 * 24 * 7) // 7 day expiration, which is the S3 maximum
  }

  if (file?.handle) {
    return `${filestackCdnUrl}/${file.handle}`
  } else if (url && !url.includes(PLACEHOLDERS_FOLDER)) {
    return url
  }
}
