import { useUserContext } from 'providers/user-provider'
import { ReactElement } from 'react'

export default function CollaborationUnavailable(): ReactElement {
  const customBranding = useUserContext().settings?.customBranding
  const shouldShowExpiredImage = (customBranding && customBranding.logo) || !customBranding
  const expiredImageSrc =
    customBranding && (customBranding.s3Logo || customBranding.logo)
      ? customBranding.s3Logo || customBranding.logo
      : '/images/icons/thinking_pickle.svg'

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center">
      {shouldShowExpiredImage && (
        <div className={'tw-mx-auto tw-mb-6 tw-block tw-h-auto tw-w-64'}>
          <img src={expiredImageSrc} alt="Expired link" className="tw-block tw-h-auto tw-w-full" />
        </div>
      )}
      <h3 className="tw-mb-2">The link you are trying to access has expired</h3>
      <div className="tw-mb-4 tw-max-w-sm">
        Contact the request owner for access.{' '}
        {!customBranding && (
          <>
            Existing Design Pickle customer? <a href="/users/sign_in">Log in here</a>
          </>
        )}
      </div>
    </div>
  )
}
