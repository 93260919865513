import { useMemo } from 'react'
import { LinkButton } from 'components/core/button'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import AnnotatedMediaViewer from './annotated-media-viewer'
import VideoJsPlayer from './video-js-player'
import { useMediaContext } from './media-provider'
import { useS3PreviewImage } from 'lib/hooks/useS3PreviewImage'
import { PLACEHOLDERS_FOLDER } from 'lib/constants/file-formats'
import { cn } from 'lib/util/cn'

interface MediaViewerProps {
  currentTicketFile: TicketFile | null
  selectedFile: TicketFile
}

export default function MediaViewer({ selectedFile, currentTicketFile }: MediaViewerProps) {
  const { isZip, zipIsExtracting } = useMediaContext()

  const previewUrl = useS3PreviewImage(selectedFile)
  const hasImagePreview = previewUrl && !previewUrl.includes(PLACEHOLDERS_FOLDER)
  const showImageAnnotationViewer = useMemo(() => {
    if (selectedFile.isExtractable) {
      return selectedFile.extractedPages?.length > 0
    }
    return hasImagePreview
  }, [selectedFile.isExtractable, selectedFile.extractedPages, hasImagePreview])

  const parentTicketFileId = useMemo(() => {
    return currentTicketFile?.id === selectedFile.id ? undefined : selectedFile.id
  }, [currentTicketFile?.id, selectedFile.id])

  if (selectedFile.isVideo) {
    return <VideoJsPlayer ticketFile={selectedFile} />
  }

  if (showImageAnnotationViewer) {
    return (
      <AnnotatedMediaViewer
        isExtractable={selectedFile.isExtractable}
        parentTicketFileId={parentTicketFileId}
        ticketFile={currentTicketFile}
      />
    )
  }

  function externalLinkFormatType() {
    if (selectedFile.externalLink.includes('canva.com')) return 'Canva'
    if (selectedFile.externalLink.includes('docs.google.com/presentation')) return 'Google Slides'
    return 'External'
  }

  const getContent = () => {
    let title = 'Preview and annotations are unavailable for this type of file.'
    let body = 'To view the file, click the download button or external link button.'
    let image = `/images/${previewUrl}`

    // Checking the date based on the prod flipper flag. We need to not check unzip for files created before the flipper was enabled.
    if (new Date(selectedFile.createdAt) < new Date('2025-01-14')) {
      return { title, body, image }
    }

    if (isZip && zipIsExtracting) {
      title = `Unzipping "${selectedFile.name}"`
      body = 'Any assets that can be annotated on will be added to the asset container shortly.'
      image = '/images/progress/zip.gif'
    } else if (isZip && !zipIsExtracting) {
      title = 'We have extracted all files you can annotate on from the zip file.'
      body = 'You can now see them in the asset container.'
    }

    return { title, body, image }
  }

  const { title, body, image } = getContent()

  return (
    <div>
      <div className="tw-px-6 tw-py-6">
        <h4>{title}</h4>
        {body}
      </div>
      <img
        src={image}
        className={cn('tw-px-6', zipIsExtracting && 'tw-w-full')}
        alt=""
        data-testid="placeholder-image"
      />
      {selectedFile.externalLink && (
        <div className="tw-mt-4 tw-flex tw-justify-center">
          <LinkButton url={selectedFile.externalLink} color="lightGray" target="_blank">
            Open {externalLinkFormatType()} Link
          </LinkButton>
        </div>
      )}
    </div>
  )
}
