import React, { useState } from 'react'
import { Editor } from '@tiptap/react'
import Button from 'components/core/button'
import { init as filestackInit } from 'filestack-js'
import { useAdminUserContext } from 'providers/admin-user-provider'
import Modal from '../modals/modal'
import { WysiwygFileUpload } from './wysiwyg-toolbar'
import { useBytescaleUploader } from '../file-uploader/file-uploader'
import { useFeatureFlagsContext } from '../feature-flags/feature-flags-provider'

interface WYSIWYGFileModalProps {
  editor: Editor
  onFilesUploaded: (files: WysiwygFileUpload[]) => void
}

interface Token {
  cancel?: () => void
}

const WYSIWYGFileModal = ({ editor, onFilesUploaded }: WYSIWYGFileModalProps) => {
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false)
  const [numFilesUploading, setNumFilesUploading] = useState<number>(0)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [uploadToken, setUploadToken] = useState<Token>()
  const { settings } = useAdminUserContext()
  const uploadManager = useBytescaleUploader()
  const client = settings?.filestackApiKey ? filestackInit(settings.filestackApiKey) : null
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const bytescaleEnabled = isFeatureFlagEnabled('bytescale_uploaders')

  const onFilesChange = async (fileList: FileList) => {
    const files = Array.from(fileList)
    setNumFilesUploading(files.length)
    setShowUploadModal(true)

    if (bytescaleEnabled && uploadManager) {
      try {
        const uploadedFiles = await Promise.all(
          files.map(async (file) => {
            const uploadedFile = await uploadManager.upload({
              data: file,
              onProgress: ({ progress }) => {
                setUploadProgress(progress)
              },
            })

            if (!uploadedFile) {
              throw new Error('Upload failed')
            }

            return {
              url: uploadedFile.filePath,
              filename: uploadedFile.originalFileName,
              mimetype: uploadedFile.mime,
              isFilestack: false,
            }
          }),
        )

        setShowUploadModal(false)
        onFilesUploaded(uploadedFiles)
      } catch (error) {
        console.error('Error uploading files with Bytescale:', error)
        setShowUploadModal(false)
      }
    } else if (client) {
      const token = {} as Token
      client
        .multiupload(
          files,
          {
            onProgress: ({ totalPercent }) => {
              if (!uploadToken?.cancel) {
                setUploadToken(token)
              }
              setUploadProgress(totalPercent)
            },
            progressInterval: 100,
          },
          {},
          token,
        )
        .then(async (res) => {
          setShowUploadModal(false)
          onFilesUploaded(
            res
              .filter(({ status }) => status === 'InTransit')
              .map((file) => {
                const normalizedFile: WysiwygFileUpload = {
                  url: file.url,
                  filename: file._file.name,
                  mimetype: file._file.type,
                  isFilestack: true,
                }
                return normalizedFile
              }),
          )
        })
    }
  }

  const cancelUpload = () => {
    setShowUploadModal(false)
    uploadToken?.cancel?.()
  }

  editor.setOptions({
    editorProps: {
      handleDrop: (_view, event, _slice, moved) => {
        if (!moved && event?.dataTransfer?.files?.length > 0) {
          onFilesChange(event.dataTransfer.files)
          return true
        }
        return false
      },
      handlePaste: (_view, event) => {
        if (event?.clipboardData?.files?.length > 0) {
          onFilesChange(event.clipboardData.files)
          return true
        }
        return false
      },
    },
  })

  return (
    <Modal open={showUploadModal}>
      Uploading {numFilesUploading} files: {uploadProgress}%
      <hr />
      <Button color="lightGray" className="tw-float-right" onClick={cancelUpload}>
        Cancel
      </Button>
    </Modal>
  )
}

export default WYSIWYGFileModal
