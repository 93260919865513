import { baseRequest, BaseResponse, getApi } from '../api'

export interface SkillCategory {
  id: number
  name: string
  skills: SkillCategorySkill[]
}

export interface SkillCategorySkill {
  id: number
  name: string
  imageUrl: string
  formats: string[]
  enabled: boolean
  forPrint: boolean
  forWeb: boolean
  skillCategoryName: string
}

export interface Skill extends SkillCategorySkill {
  formats: string[]
  skillSizes?: SkillSize[]
}

interface SkillCategoryResponse extends BaseResponse {
  skillCategories: SkillCategory[]
}

interface SkillsResponse extends BaseResponse {
  skills: Skill[]
}

export interface SkillSize {
  id: number
  details: string
  name: string
  width?: number
  height?: number
  units?: string
}

export interface CustomSize {
  width: number | ''
  height: number | ''
  units: string
}

export interface SkillResponse extends BaseResponse {
  skill: Skill
}

const skillsBaseUrl = '/api/internal/skills'

export async function getSkill(skillId: number): Promise<SkillResponse> {
  const url = `${skillsBaseUrl}/${skillId}`
  const response = await baseRequest(url, getApi)

  return {
    error: response.error,
    status: response.status,
    skill: response.data as unknown as Skill,
  }
}

export async function getSkills(): Promise<SkillCategoryResponse> {
  const response = await baseRequest(skillsBaseUrl, getApi)

  return {
    error: response.error,
    status: response.status,
    skillCategories: response.data as unknown as SkillCategory[],
  }
}

export async function getMostUsedSkills(): Promise<SkillsResponse> {
  const response = await baseRequest(`${skillsBaseUrl}/most_used`, getApi)

  return {
    error: response.error,
    status: response.status,
    skills: response.data as unknown as Skill[],
  }
}
