import Button, { ButtonProps } from 'components/core/button'
import { useAdminMediaContext } from 'components/pages/admin/admin-ticket/providers/admin-media-provider'
import { useAdminTicketContext } from 'components/pages/admin/admin-ticket/providers/admin-ticket-provider'
import { useMediaContext } from 'components/pages/request/media/media-provider'
import { AdminTicketFile, AdminTicketFileState } from 'lib/api/admin/ticket-files/admin-ticket-files'
import { File, generateAndDownloadZip } from 'lib/util/file/file'
import { Download, LoaderCircle } from 'lucide-react'
import { useEffect, useState } from 'react'
import { toast } from 'lib/components/toast/toast'
import { cn } from 'lib/util/cn'

const DownloadButton = ({
  onClick,
  progress,
  className,
  buttonColor = 'neutralGray',
  buttonText = 'Download All',
}: {
  onClick: () => void
  loading?: boolean
  progress?: number
  className?: HTMLButtonElement['className']
  buttonColor?: ButtonProps['color']
  buttonText?: string
}) => (
  <Button color={buttonColor} onClick={onClick} className={cn('tw-flex tw-items-center tw-gap-2', className)}>
    {progress ? (
      <>
        <LoaderCircle className="lu-sm tw-animate-spin" />{' '}
        <span className="tw-min-w-32">{Math.round(progress)}% Downloaded</span>
      </>
    ) : (
      <>
        <Download className="lu-sm" /> <span>{buttonText}</span>
      </>
    )}
  </Button>
)

export function DownloadFilesButton({
  files,
  zipName = `download_${new Date().toLocaleDateString().replaceAll('/', '')}`,
  onDownload = () => {},
  onError = () => {},
  onProgressChange = () => {},
  className,
  buttonColor,
  buttonText,
}: {
  files: File[]
  zipName?: string
  onDownload?: () => void
  onError?: () => void
  onProgressChange?: (progress: number) => void
  className?: HTMLButtonElement['className']
  buttonColor?: ButtonProps['color']
  buttonText?: string
}) {
  const [progress, setProgress] = useState(null)

  useEffect(() => {
    onProgressChange(progress)
  }, [onProgressChange, progress])

  async function handleDownloadAllAssets() {
    setProgress(0)
    try {
      await generateAndDownloadZip(files, zipName, setProgress)
      onDownload()
    } catch (e) {
      onError()
      toast.error('Error downloading assets')
      console.error('Error downloading assets', e)
    }
    setProgress(null)
  }
  return (
    <DownloadButton
      progress={progress}
      onClick={handleDownloadAllAssets}
      className={className}
      buttonColor={buttonColor}
      buttonText={buttonText}
    />
  )
}

export function DownloadAllAssetsButton({
  version,
  className,
  buttonColor,
  buttonText,
}: {
  version?: number
  className?: HTMLButtonElement['className']
  buttonColor?: ButtonProps['color']
  buttonText?: string
}) {
  const { files, selectedVersion, ticket } = useMediaContext()
  const verionToDownload = version ?? selectedVersion

  const potentiallyDownloadableFiles = files.filter(
    (file) =>
      file.uploadedByCreative && !file.preview && (file.ticketVersion === selectedVersion || selectedVersion === 0),
  )
  const filesToBeZipped = potentiallyDownloadableFiles.filter((file) => file.downloadUrl)
  const filesMissingFromZip = potentiallyDownloadableFiles.filter(
    (potentiallyDownloadableFile) =>
      !filesToBeZipped.some((zippedFile) => zippedFile.id === potentiallyDownloadableFile.id),
  )

  const downloadDate = new Date().toLocaleDateString().replaceAll('/', '')
  const fileNameVersion = verionToDownload === 0 ? '' : `_V${verionToDownload}`
  const zipName = `${ticket.id}_${ticket.subject}_${downloadDate}${fileNameVersion}`

  const onDownload = () => {
    if (filesMissingFromZip.length > 0) {
      toast.error('Some files could not be added to the zip file')
      console.error('Some files could not be added to the zip file', filesMissingFromZip)
    } else {
      toast.success('Assets downloaded successfully')
    }
  }

  return (
    <DownloadFilesButton
      files={filesToBeZipped.map((file) => ({ name: file.name, url: file.downloadUrl }))}
      onDownload={onDownload}
      zipName={zipName}
      className={className}
      buttonColor={buttonColor}
      buttonText={buttonText}
    />
  )
}

export function DownloadAllAdminAssetsButton({ files }: { files: AdminTicketFile[] }) {
  const { ticket } = useAdminTicketContext()
  const { filters } = useAdminMediaContext()

  const potentiallyDownloadableFiles = files.filter(
    (file) => !(file.state === AdminTicketFileState.Rejected || file.preview),
  )

  const filesToBeZipped = potentiallyDownloadableFiles.filter((file) => file.downloadUrl)

  // Files that are not in the filesToBeZipped array, but the user might expect to be included
  const filesMissingFromZip = potentiallyDownloadableFiles.filter(
    (potentiallyDownloadableFile) =>
      !filesToBeZipped.some((zippedFile) => zippedFile.id === potentiallyDownloadableFile.id),
  )

  const downloadDate = new Date().toLocaleDateString().replaceAll('/', '')
  const version = filters.version === 0 ? '' : `_V${filters.version}`
  const zipName = `${ticket.id}_${ticket.subject}_${downloadDate}${version}`

  const onDownload = () => {
    if (filesMissingFromZip.length > 0) {
      toast.error('Some files could not be added to the zip file')
      console.error('Some files could not be added to the zip file', filesMissingFromZip)
    } else {
      toast.success('Assets downloaded successfully')
    }
  }

  return (
    <DownloadFilesButton
      files={filesToBeZipped.map((file) => ({ name: file.name, url: file.downloadUrl }))}
      onDownload={onDownload}
      zipName={zipName}
    />
  )
}
