import { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import IconButton from 'lib/components/buttons/icon-button'
import DetailTask, { DetailTaskType } from '../types/detail-task'
import TextInput from 'lib/components/text-input/text-input'
import Textarea, { TextCounter } from 'lib/components/textarea/textarea'
import { useDirectionsContext } from '../providers/timeline-directions-provider'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import WYSIWYGTextarea, { footerClassNames } from 'lib/components/wysiwyg/wysiwyg-textarea'

const DEFAULT_PLACEHOLDER = 'Start typing general direction'

interface DirectionsInputProps {
  direction?: DetailTask
  focusOnInitialLoad?: boolean
  onEscapeKeyPress?: () => void
  onUpdated?: () => void
  placeholder?: string
  onXButtonClick?: () => void
}

interface InputProps {
  value: string
  maxLength?: number
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  placeholder: string
  error: boolean
  onKeyDown: (e: KeyboardEvent) => void
}

const minimumLength = 3

export default function DirectionsInput({
  direction,
  focusOnInitialLoad = false,
  onEscapeKeyPress,
  onUpdated,
  placeholder,
  onXButtonClick,
}: DirectionsInputProps): ReactElement {
  const inputRef = useRef(null)
  const [value, setValue] = useState(direction?.description || '')
  const { addTask, update, setNewDirectionHasValue, directions, showDirectionErrors } = useDirectionsContext()
  const [showAttemptedSubmitError, setShowAttemptedSubmitError] = useState(false)
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const showWYSIWYG = isFeatureFlagEnabled('request_wysiwyg')
  const [renderKey, setRenderKey] = useState(1)

  const isNewDirection = useMemo(() => !direction?.id, [direction?.id])
  const showError = useMemo(() => {
    if (isNewDirection) {
      if (directions?.length === 0) {
        return showDirectionErrors || showAttemptedSubmitError
      }

      return (
        (showDirectionErrors && value.trim().length !== 0) || (showAttemptedSubmitError && !hasMinimumLength(value))
      )
    }
    return showDirectionErrors || (showAttemptedSubmitError && !hasMinimumLength(value))
  }, [directions?.length, isNewDirection, showAttemptedSubmitError, showDirectionErrors, value])
  const showCharacterCounter = useMemo(
    () => direction?.taskType === DetailTaskType.TASK || isNewDirection,
    [direction?.taskType, isNewDirection],
  )

  const inputProps: InputProps = {
    value,
    onChange: onTextAreaChange,
    error: showError,
    placeholder: placeholder || DEFAULT_PLACEHOLDER,
    onKeyDown,
  }

  if (direction?.taskType === DetailTaskType.COPY) {
    inputProps.maxLength = -1
  }

  function hasMinimumLength(s: string) {
    return s.trim().length >= minimumLength
  }

  function clear() {
    inputRef.current.value = ''
    setValue('')
    setRenderKey((prev) => prev + 1)
  }

  function onTextAreaChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value
    onChange(newValue)
  }

  function onChange(newValue: string) {
    if (isNewDirection) {
      setNewDirectionHasValue(!!newValue)
    }

    setValue(newValue)

    if ((isNewDirection && newValue.trim().length === 0) || hasMinimumLength(newValue)) {
      setShowAttemptedSubmitError(false)
    }
  }

  async function save() {
    if (!hasMinimumLength(value)) {
      setShowAttemptedSubmitError(true)
      return
    }

    setShowAttemptedSubmitError(false)

    if (!isNewDirection) {
      await update(direction, value.trim())
      onUpdated()
    } else {
      addTask(value.trim())
      clear()
    }
  }

  async function onKeyDown(e: KeyboardEvent) {
    if ((isNewDirection && e.key === 'Enter') || (!isNewDirection && e.key === 'Enter' && (e.ctrlKey || e.metaKey))) {
      await save()
    } else if (e.key === 'Escape' && onEscapeKeyPress) {
      onEscapeKeyPress()
    }
  }

  useEffect(() => {
    if (focusOnInitialLoad) {
      if (showWYSIWYG) {
        inputRef.current?.editor?.commands?.focus?.()
      } else {
        inputRef.current.focus()
        inputRef.current.scrollTop = inputRef.current.scrollHeight
        inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showWYSIWYG])

  const footer = (
    <div className={footerClassNames}>
      <div style={{ minWidth: '40px' }}>
        <IconButton color="secondary" icon={['far', 'check']} onClick={save} />
      </div>
      {!isNewDirection && (
        <span onMouseDown={(e) => e.preventDefault()}>
          <IconButton color="secondary" icon={['far', 'times']} onClick={onXButtonClick} />
        </span>
      )}
    </div>
  )

  return (
    <>
      <div className="tw-relative tw-mb-4 tw-overflow-auto">
        {showWYSIWYG ? (
          <WYSIWYGTextarea
            ref={inputRef}
            key={renderKey}
            placeholder="Start typing general direction"
            onChange={onChange}
            footer={footer}
          />
        ) : (
          <>
            {isNewDirection && <TextInput {...inputProps} maxLength={500} ref={inputRef} />}
            {!isNewDirection && <Textarea {...inputProps} ref={inputRef} />}
          </>
        )}
        {footer}
      </div>
      {showCharacterCounter && (
        <TextCounter value={value} className={isNewDirection ? 'tw-mr-12 tw-text-right' : 'tw-mr-24 tw-text-right'} />
      )}
    </>
  )
}
