import CreateDeliverables from 'components/pages/create/create-deliverables'
import CreateProjects from 'components/pages/create/create-projects'
import { AppSettings, CurrentUser } from 'interfaces/app'
import Badge from 'lib/components/badge/badge'
import ReferredBreadcrumb from 'lib/components/breadcrumb/referred-breadcrumb'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'lib/components/tabs'
import { Toaster } from 'lib/components/toast/toast'
import UserProvider from 'providers/user-provider'
import { useEffect, useState } from 'react'
import SkillsProvider, { useSkillsContext } from '../request/skills/skills-provider'
import projectBannerImage from 'images/projects/projects_banner_image.png'
import requestBannerImage from 'images/projects/create_requests_banner.png'
import { ArrowRightIcon } from 'lucide-react'
import { LinkLikeButton } from 'components/core/button'

interface CreateProps {
  settings: AppSettings
  user: CurrentUser
}

interface TabContentsProps {
  setTab: (tab: string) => void
}

function TabContents({ setTab }: TabContentsProps) {
  const { getSkillsByCategory } = useSkillsContext()

  useEffect(() => {
    getSkillsByCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TabsContent value="requests">
        <CreateDeliverables setTab={setTab} />
      </TabsContent>
      <TabsContent value="projects">
        <CreateProjects setTab={setTab} />
      </TabsContent>
    </>
  )
}

export default function Create({ user, settings }: CreateProps) {
  const [tab, setTab] = useState<string>(new URLSearchParams(window.location.search).get('tab') || 'requests')

  return (
    <Tabs
      className="tw-mt-[var(--header-height,0px)] tw-h-[calc(100vh-var(--header-height,0px))] tw-w-screen tw-overflow-auto tw-p-5 tw-scrollbar-gutter-stable"
      value={tab}
      onValueChange={setTab}
    >
      <Toaster />
      <header>
        <ReferredBreadcrumb currentPageText="Create" />
        <CreateBanner tab={tab} setTab={setTab} />
        <div className="tw-relative tw-z-10 tw-flex tw-items-center tw-justify-between">
          <h2 className="tw-m-0 tw-mt-4 tw-justify-self-center">
            What are you looking to {tab === 'requests' ? 'create?' : 'accomplish?'}
          </h2>
          <TabsList className="tw-justify-self-end" intent="background">
            <TabsTrigger value="requests" intent="background">
              Requests
            </TabsTrigger>
            <TabsTrigger value="projects" intent="background">
              <div className="tw-mr-2">Projects</div>
              <Badge color={tab === 'projects' ? 'light' : 'primary'}>New</Badge>
            </TabsTrigger>
          </TabsList>
        </div>
      </header>
      <UserProvider settings={settings} user={user}>
        <SkillsProvider>
          <TabContents setTab={setTab} />
        </SkillsProvider>
      </UserProvider>
    </Tabs>
  )
}

function CreateBanner({ tab, setTab }: { tab: string; setTab: (tab: string) => void }) {
  const bannerContent = {
    requests: {
      title: 'Need more than one design?',
      description: (setTab: (tab: string) => void) => (
        <p className="tw-text-base tw-text-skyblue-50">
          Manage multiple design requests in one place with ready-to-use campaign templates.{' '}
          <LinkLikeButton className="tw-text-white hover:tw-text-skyblue-50" onClick={() => setTab('projects')}>
            Start here <ArrowRightIcon />
          </LinkLikeButton>
        </p>
      ),
      backgroundColor: 'tw-bg-skyblue-800',
      image: requestBannerImage,
    },
    projects: {
      title: 'Creative planning made easy.',
      description: () => (
        <p className="tw-text-base">
          Create a project to organize your next launch. Choose a template below or customize with a blank project.
        </p>
      ),
      backgroundColor: 'tw-bg-neutral-800',
      image: projectBannerImage,
    },
  }

  return (
    <div
      className={`tw-my-11 tw-flex tw-items-center tw-justify-between tw-gap-8 tw-overflow-visible tw-rounded-lg tw-text-white ${bannerContent[tab].backgroundColor}`}
    >
      <div className="tw-w-full tw-px-10 tw-py-4">
        <div className="tw-max-w-prose">
          <h1 className="tw-text-2xl tw-text-white">{bannerContent[tab].title}</h1>
          {bannerContent[tab].description(setTab)}
        </div>
      </div>
      <img src={bannerContent[tab].image} alt="Request" className="tw-relative tw-right-16 -tw-my-20 tw-h-80" />
    </div>
  )
}
