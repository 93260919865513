import { ReactElement, useState } from 'react'
import { useRequestContext } from '../providers/request-provider'
import HeaderActions from './header-actions'
import RequestName from './request-name'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import HeaderDropdowns from './header-dropdowns'
import Breadcrumb from 'lib/components/breadcrumb/breadcrumb'
import HeaderRequestDetails from 'components/pages/request/request-header/header-request-details'
import CloseButton from '../media/close-button'
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react'

import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import RequestsProjectsBreadcrumb from 'lib/components/breadcrumb/requests-projects-breadcrumb'
import Banner from 'lib/components/banner/banner'
import { revisionsCanBeSentToDesigner, isStatusInProgress, isStatusInReview } from 'lib/ticket/utils'
import ConfirmNavigatePendingUpdatesModal from './confirm-navigate-pending-updates-modal'

function RequestDetails(): ReactElement {
  const { ticket, invalidFields, showValidationErrors } = useRequestContext()

  return (
    <div className="tw-w-full">
      <div className="tw-mb-2 tw-flex tw-flex-wrap tw-items-center tw-gap-4">
        <div className="tw-flex tw-items-center tw-gap-2">
          <RequestName showValidationError={invalidFields?.includes('subject') && showValidationErrors} />
          <TicketStatusBadge status={ticket?.status} className="tw-mx-0" />
        </div>
      </div>
    </div>
  )
}

export default function RequestHeader(): JSX.Element {
  const [isOpen, setIsOpen] = useState(true)
  const { ticket, showRevisions, fetchAndSetTicket, editMode } = useRequestContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const [confirmationVisible, setConfirmationVisible] = useState(false)

  const hasEdits = revisionsCanBeSentToDesigner(ticket, editMode)

  function toggleOpen() {
    setIsOpen(!isOpen)
  }

  return (
    <header className="tw-relative">
      {isFeatureFlagEnabled('projects') ? (
        <RequestsProjectsBreadcrumb
          className="tw-ml-6 tw-mt-3"
          ticket={ticket}
          onProjectUpdate={() => (hasEdits ? setConfirmationVisible(true) : fetchAndSetTicket())}
        />
      ) : hasEdits ? (
        <Breadcrumb
          className="tw-ml-6 tw-mt-3"
          text="Back to all requests"
          onClick={() => setConfirmationVisible(true)}
        />
      ) : (
        <Breadcrumb className="tw-ml-6 tw-mt-3" text="Back to all requests" href="/requests" />
      )}
      <div className="tw-flex tw-w-full tw-items-start tw-justify-between tw-px-3 tw-pb-3 tw-pt-4">
        <div className="tw-w-4/6">
          <RequestDetails />
        </div>
        <div className="tw-text-right">
          <HeaderActions />
        </div>
      </div>
      {(isStatusInProgress(ticket.status) || isStatusInReview(ticket.status)) && (
        <Banner type="info">
          Please note that your request is in progress. Replies to your designer that contain directions for revision
          may not be accommodated today.
        </Banner>
      )}
      {isOpen && (
        <div>
          {!showRevisions && <HeaderDropdowns />}
          {showRevisions && <HeaderRequestDetails />}
        </div>
      )}
      <CloseButton Icon={isOpen ? ChevronUpIcon : ChevronDownIcon} onClick={toggleOpen} position="bottom" />
      <ConfirmNavigatePendingUpdatesModal ticketId={ticket?.id} isOpen={confirmationVisible} />
    </header>
  )
}
