import { UploadDropzone, UploadDropzoneProps } from '@bytescale/upload-widget-react'
import type { UploadWidgetResult } from '@bytescale/upload-widget'
import * as Bytescale from '@bytescale/sdk'
import Modal from '../modals/modal'
import { useEnvVars } from 'hooks/use-env-vars'
import { cn } from 'lib/util/cn'

const _100MB = 1024 * 1024 * 100
const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--color-cornflower-500')

const defaultOptions: UploadDropzoneProps['options'] = {
  apiKey: '',
  maxFileCount: 50,
  styles: {
    colors: {
      primary: primaryColor ? primaryColor : '#576EE7',
    },
  },
  maxFileSizeBytes: _100MB,
  mimeTypes: [
    'application/illustrator',
    'application/postscript',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-fontobject',
    'application/eps',
    'application/postscript',
    'application/figma',
    'application/x-indesign',
    'application/vnd.apple.keynote',
    'application/mogrt',
    'font/otf',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-powerpoint.presentation.macroenabled.12',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/prproj',
    'application/vnd.rar',
    'application/x-rar-compressed',
    'application/sketch',
    'font/ttf',
    'font/woff',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/zip',
    'application/octet-stream',
    'application/x-zip-compressed',
    'multipart/x-zip',
    'audio/*',
    'image/*',
    'text/*',
    'video/*',
  ],
  showFinishButton: true,
  showRemoveButton: true,
  editor: {
    images: {
      crop: false,
      preview: false,
    },
  },
  locale: {
    finishBtn: 'Upload',
  },
}

interface FileUploaderProps {
  options?: Omit<UploadDropzoneProps['options'], 'apiKey'> & {
    path?: {
      folderPath?: string
    }
  }
  onUpdate?: UploadDropzoneProps['onUpdate']
  onComplete?: UploadDropzoneProps['onComplete']
  className?: HTMLDivElement['className']
}

function FileUploader({ options, onUpdate, onComplete, className }: FileUploaderProps) {
  const { bytescalePublicApiKey, bytescaleS3AliasBucket } = useEnvVars()

  const requiredVars = { bytescalePublicApiKey, bytescaleS3AliasBucket }
  Object.entries(requiredVars).forEach(([key, value]) => {
    if (!value) console.warn(`${key} is not set`)
  })

  const apiOptions = {
    apiKey: bytescalePublicApiKey,
    path: {
      folderPath: options?.path?.folderPath
        ? `/${bytescaleS3AliasBucket}${options.path.folderPath}`
        : `/${bytescaleS3AliasBucket}`,
    },
  }

  const combinedOptions = { ...defaultOptions, ...options, ...apiOptions }
  return (
    <UploadDropzone
      options={combinedOptions}
      onUpdate={onUpdate}
      onComplete={onComplete}
      className={cn('tw-w-full', className)}
    />
  )
}

export type { UploadWidgetResult as FileUploaderResult }

export function InlineFileUploader({ options, onUpdate, onComplete, className }: FileUploaderProps) {
  return <FileUploader options={options} onUpdate={onUpdate} onComplete={onComplete} className={className} />
}

export function ModalFileUploader({
  options,
  onUpdate,
  onComplete,
  isOpen,
  setIsOpen,
}: FileUploaderProps & { isOpen?: boolean; setIsOpen?: (open: boolean) => void }) {
  return (
    <Modal
      open={isOpen ?? true}
      setOpen={setIsOpen ?? (() => {})}
      size="md"
      className="w-full tw-flex tw-items-center tw-justify-stretch [&>div]:tw-w-full"
    >
      <FileUploader options={options} onUpdate={onUpdate} onComplete={onComplete} />
    </Modal>
  )
}

export function useBytescaleUploader() {
  const { bytescalePublicApiKey, bytescaleS3AliasBucket } = useEnvVars()

  if (!bytescalePublicApiKey) {
    console.error('bytescalePublicApiKey is not set')
    return null
  }

  const uploadManager = new Bytescale.UploadManager({
    apiKey: bytescalePublicApiKey,
  })

  return {
    ...uploadManager,
    upload: async (options: Parameters<typeof uploadManager.upload>[0]) => {
      return uploadManager.upload({
        ...{
          maxConcurrentUploads: 3,
          path: {
            folderPath: `/${bytescaleS3AliasBucket}`,
          },
        },
        ...options,
      })
    },
  }
}
