import { Drawer } from 'lib/components/drawer/drawer'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import IconButton from 'lib/components/buttons/icon-button'
import { ArrowRightToLine, FolderArchiveIcon } from 'lucide-react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { displayDate } from 'lib/util/date'
import CompanyTaggingProvider, { ChangeTagAction } from 'providers/company-tagging-provider'
import CompanyTaggingPopover from 'components/elements/company-tagging/company-tagging-popover'
import { CompanyTag } from 'lib/api/company-tags/company-tags'
import { useAllCompanyTagsContext } from 'providers/all-company-tags-provider'
import { useMediaContext } from './media-provider'
import { getShareLinkUrl } from 'lib/util/file/file'
import { useS3PreviewImage } from 'lib/hooks/useS3PreviewImage'

interface MediaDrawerProps {
  file: TicketFile
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export default function MediaDrawer({ file, isOpen, setIsOpen }: MediaDrawerProps) {
  const [selectedTags, setSelectedTags] = useState(file.tags || [])
  const [shareLinkUrl, setShareLinkUrl] = useState<string | null>(null)

  const { companyTags } = useAllCompanyTagsContext()
  const { zipFile } = useMediaContext()

  useEffect(() => {
    const fetchShareLinkUrl = async () => {
      const url = await getShareLinkUrl(file)
      setShareLinkUrl(url)
    }
    fetchShareLinkUrl()
  }, [file])

  useEffect(() => {
    setSelectedTags(file.tags || [])
  }, [file.id, file.tags])

  useEffect(() => {
    setSelectedTags((prevSelectedTags) => {
      return prevSelectedTags
        .filter((selectedTag) => companyTags.some((companyTag) => companyTag.id === selectedTag.id))
        .map((selectedTag) => companyTags.find((tag) => tag.id === selectedTag.id))
    })
  }, [companyTags])

  function handleUpdateTaggableState(tag: CompanyTag, action: ChangeTagAction) {
    switch (action) {
      case ChangeTagAction.Select:
        setSelectedTags([...selectedTags, tag])
        break
      case ChangeTagAction.Unselect:
      case ChangeTagAction.Delete:
        setSelectedTags(selectedTags.filter((selectedTag) => selectedTag.id !== tag.id))
        break
      case ChangeTagAction.Update:
        setSelectedTags(selectedTags.map((selectedTag) => (selectedTag.id === tag.id ? tag : selectedTag)))
        break
      default:
        break
    }
  }

  const previewUrl = useS3PreviewImage(file)
  return (
    <Drawer isOpen={isOpen} setOpen={setIsOpen} size="md">
      <Drawer.Header setOpen={setIsOpen} showCloseButton={false}>
        <div className="tw-flex tw-items-center">
          <IconButton color="secondary" onClick={() => setIsOpen(false)}>
            <ArrowRightToLine />
          </IconButton>
          <img src={previewUrl} alt={file.name} className="tw-mx-4 tw-h-14 tw-w-14" />
          <div>
            <h5>{file.name}</h5>
            <div>Created on {displayDate(file.createdAt)}</div>
          </div>
        </div>
      </Drawer.Header>
      <Drawer.Body>
        <div>
          <h5>Tags</h5>
          <CompanyTaggingProvider
            selectedTags={selectedTags}
            updateTaggableState={handleUpdateTaggableState}
            taggableId={file.id}
            taggableType="TicketFile"
            warningMessage="Adding and removing asset tags does not add nor remove tags from the associated requests"
          >
            <CompanyTaggingPopover size="full" />
          </CompanyTaggingProvider>
        </div>
        {zipFile && (
          <div>
            <h5>Source</h5>
            <a href={shareLinkUrl} target="_blank" rel="noreferrer" className="tw-text-cornflower-500">
              <FolderArchiveIcon className="tw-mr-1 tw-h-4 tw-w-4" />
              via {zipFile.name}
            </a>
          </div>
        )}
      </Drawer.Body>
    </Drawer>
  )
}
