import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, HTMLAttributes, useEffect } from 'react'
import Button from 'components/core/button'
import { cn } from 'lib/util/cn'

function HeaderCloseButton({
  setOpen,
  invert = false,
}: {
  setOpen: (open: boolean) => void
  invert: boolean
}): JSX.Element {
  const closeIconColorClasses = invert
    ? 'tw-text-white hover:tw-text-white hover:tw-bg-neutral-500'
    : 'tw-text-neutral-800 hover:tw-text-neutral-800 hover:tw-bg-neutral-100'

  return (
    <a
      href="#"
      className={`tw-z-40 tw-mt-1 tw-rounded-full tw-p-2 tw-no-underline tw-transition-colors ${closeIconColorClasses}`}
      onClick={() => setOpen(false)}
      data-testid="drawer-close"
    >
      <FontAwesomeIcon icon={['fal', 'arrow-to-right']} size="lg" />
    </a>
  )
}

interface HeroHeaderProps {
  imgUrl?: string
  setOpen: (open: boolean) => void
  children?: React.ReactNode
  className?: string
  heightClassName?: string
  invertCloseColor?: boolean
}

function HeroHeader({
  imgUrl,
  className,
  heightClassName,
  setOpen,
  children,
  invertCloseColor = false,
}: HeroHeaderProps): JSX.Element {
  return (
    <>
      <header
        className={`tw-sticky tw-top-0 tw-z-30 tw-w-full tw-flex-shrink-0 tw-bg-contain tw-bg-center tw-p-4 ${
          className || ''
        } ${heightClassName || 'tw-h-40'}`}
        style={imgUrl && { backgroundImage: `url(${imgUrl})` }}
      >
        <div>
          <HeaderCloseButton setOpen={setOpen} invert={invertCloseColor} />
        </div>
        {children}
      </header>
    </>
  )
}

function Title({ children, className }: { children: React.ReactNode; className?: string }) {
  return <Dialog.Title className={`tw-m-0 ${className || ''}`}>{children}</Dialog.Title>
}

interface HeaderProps {
  setOpen: (open: boolean) => void
  children?: React.ReactNode
  title?: React.ReactNode
  invertCloseColor?: boolean
  showCloseButton?: boolean
  className?: HTMLAttributes<HTMLDivElement>['className']
}

function Header({
  title,
  children,
  setOpen,
  invertCloseColor = false,
  showCloseButton = true,
  className,
}: HeaderProps) {
  return (
    <header
      className={cn(
        'tw-sticky tw-left-0 tw-top-0 tw-z-10 tw-m-0 tw-w-full tw-gap-4 tw-border-0 tw-border-b tw-border-solid tw-border-peppercorn-100 tw-bg-white tw-p-4',
        className,
      )}
    >
      <div className="tw-flex tw-items-center">
        {showCloseButton && <HeaderCloseButton setOpen={setOpen} invert={invertCloseColor} />}
        {title && <h3 className="tw-ml-2">{title}</h3>}
      </div>
      {children && children}
    </header>
  )
}

function Body({ children, className }: { children: React.ReactNode; className?: string }) {
  return <div className={cn(`tw-w-full tw-flex-grow tw-px-6 tw-py-4`, className)}>{children}</div>
}

interface FooterProps {
  onCancel: () => void
  onSubmit: () => void
  cancelText?: string
  submitText?: string
}

function Footer({ onCancel, onSubmit, cancelText = 'Cancel', submitText = 'Save' }: FooterProps) {
  return (
    <footer className="tw-sticky tw-bottom-0 tw-right-0 tw-flex tw-w-full tw-items-center tw-justify-end tw-gap-4 tw-bg-white tw-p-4">
      <Button type="button" color="lightGray" onClick={onCancel}>
        {cancelText}
      </Button>
      <Button type="button" color="purple" onClick={onSubmit}>
        {submitText}
      </Button>
    </footer>
  )
}

interface DrawerProps {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  size: 'xl' | 'lg' | 'md' | 'sm'
  children: React.ReactNode
}

function BaseDrawer({ isOpen, setOpen, size, children }: DrawerProps): JSX.Element {
  const sizeClasses = {
    sm: 'tw-w-full md:tw-max-w-[33.3vw]',
    md: 'tw-w-full md:tw-max-w-[50vw]',
    lg: 'tw-w-full md:tw-max-w-[75vw]',
    xl: '',
  }

  useEffect(() => {
    function handleDisableIntercom() {
      toggleElement(document.querySelector('.intercom-launcher') as HTMLElement)
      toggleElement(document.querySelector('#intercom-container') as HTMLElement)
    }

    function toggleElement(el: HTMLElement) {
      if (el) {
        el.style.display = isOpen ? 'none' : 'block'
      }
    }

    handleDisableIntercom()
  }, [isOpen])

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog className="tw-absolute tw-z-30" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="tw-ease-in-out tw-duration-500"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in-out tw-duration-500"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
          <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
            <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex tw-max-w-full tw-pl-10">
              <Transition.Child
                as={Fragment}
                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 tw-sm:duration-700"
                enterFrom="tw-translate-x-full"
                enterTo="tw-translate-x-0"
                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 tw-sm:duration-700"
                leaveFrom="tw-translate-x-0"
                leaveTo="tw-translate-x-full"
              >
                <Dialog.Panel className={`tw-pointer-events-auto tw-w-screen ${sizeClasses[size]}`}>
                  <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-start tw-justify-start tw-overflow-y-auto tw-overflow-x-hidden tw-bg-white tw-shadow-xl">
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const Drawer = Object.assign(BaseDrawer, { HeroHeader, Header, Title, Body, Footer })
