export const PATHS = {
  // Tickets
  ADMIN_TICKETS: '/admin/tickets',
  ADMIN_TICKETS_ME: '/admin/tickets/me',
  ADMIN_TICKETS_QUEUE: '/admin/tickets/queue?type=assigned_to_me',
  ADMIN_ASSIGNED_TO_TEAM: '/admin/tickets/assigned_to_team',

  // People
  ADMIN_COMPANIES: '/admin/companies',
  ADMIN_USERS: '/admin/users',

  // Team
  ADMIN_APMS: '/admin/apms',
  ADMIN_DESIGNERS: '/admin/designers',
  ADMIN_QA_SPECIALISTS: '/admin/qa_specialists',
  ADMIN_TAG_TEAMS: '/admin/tag_teams',
  ADMIN_TEAMS_DASHBOARD: '/admin/dashboard',
  ADMIN_QR_TEAMS: '/admin/qr_teams',

  // Reports
  ADMIN_ADVANCED_ANALYTICS: '/admin/advanced_analytics',
  ADMIN_TICKETS_OVERVIEW: '/admin/tickets/overview',
  ADMIN_DESIGNERS_OVERVIEW: '/admin/designers/overview',
  ADMIN_ATTENDANCE: '/admin/designers/attendance',
  ADMIN_REPORTS_CANCELLATIONS: '/admin/reports/cancellations',
  ADMIN_CONVERSATIONS: '/admin/conversations',
  ADMIN_QA_REVIEWS: '/admin/qa_reviews',
  ADMIN_DESIGNER_SENTIMENTS: '/admin/reports/designer_sentiments',
  ADMIN_DESIGNER_TRACKINGS: '/admin/designer_trackings',
  ADMIN_REQUESTS: '/admin/requests',
  ADMIN_REQUEST_ABANDONMENTS: '/admin/reports/request_abandonments',
  ADMIN_TICKET_FEEDBACKS: '/admin/ticket_feedbacks',
  ADMIN_AVAILABILITY_SCHEDULE_CALENDAR: '/admin/availability_schedule/calendar',

  // Tools
  ADMIN_WATSON_INTERNSHIP: '/admin/watson/internship',
  ADMIN_TAGS: '/admin/tags',
  ADMIN_WIKI: 'https://library.designpickle.com/',

  // Auto assigner
  ADMIN_PRO_SUBSCRIPTIONS: '/admin/pro_subscriptions',
  ADMIN_SCALE_SUBSCRIPTIONS: '/admin/scale_subscriptions',
  ADMIN_COMPANY_DESIGNER_ALLOCATIONS: '/admin/company_designer_allocations',
  ADMIN_BLOCKLISTS: '/admin/blocklists',
  ADMIN_AUTO_ASSIGNER_TIME_ZONES: '/admin/auto_assigner_time_zones',

  // Freshdesk
  ADMIN_FRESH_STOCK_ASSETS: '/admin/fresh_stock_assets',

  // Customer success
  ADMIN_ANNOUNCEMENTS: '/admin/announcements',
  ADMIN_CANNED_RESPONSES: '/admin/canned_responses',
  ADMIN_PICKLE_COUPONS: '/admin/pickle_coupons',
  ADMIN_EMAILER: '/admin/emailer',
  ADMIN_PICKLE_PLANS: '/admin/pickle_plans',

  // Production
  ADMIN_FEEDBACK: '/admin/feedback',
  ADMIN_SKILL_CATEGORIES: '/admin/skill_categories',
  ADMIN_SKILLS: '/admin/skills',
  ADMIN_FORMATS: '/admin/formats',
  ADMIN_PROJECT_TEMPLATES: '/admin/projects',
  ADMIN_AVAILABILITY_SCHEDULE: '/admin/availability_schedule',

  // Sales & Marketing
  ADMIN_COMPANIES_NEW: '/admin/companies/new',

  ADMIN_TIME_ALLOCATIONS: '/admin/company_daily_available_times',

  REQUESTS: '/requests',
  ASSETS: '/assets',
  BRANDS: '/brands',
  COMPANIES_WHITE_LABEL: '/companies/white_label',

  SETTINGS: '/settings',
  COMPANIES_SETTINGS: '/companies/settings',
  COMPANIES_TEAM: '/companies/team',
  SETTINGS_NOTIFICATIONS: '/settings/notifications',
  REQUEST_TYPES: '/request_types',
  RECENT_UPDATES: '/recent_updates',
  HELP: 'https://help.designpickle.com/en/',
  FRESH_STOCK: '/fresh_stock',
  STATUS_PAGE: 'https://designpickle.statuspage.io/',

  ADMIN_NOTIFICATIONS: '/admin/notifications',

  BACK_TO_ADMIN: '/users/back_to_admin',
  SEARCH: '/search',
  ROOT: '/',

  SIGN_OUT: '/users/sign_out',

  KB_REQUEST_CUTOFF_TIMES:
    'https://help.designpickle.com/en/articles/2670518-production-schedule-cut-off-times-shift-hours-time-zones',
  KB_PERMISSIONS: 'https://help.designpickle.com/en/articles/4151855-how-to-manage-user-roles-permissions',

  HUBSPOT_MEETING: 'https://meetings.hubspot.com/picklechat/explore-design-pickle',
} as const
