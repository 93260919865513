import { useMutation } from '@tanstack/react-query'
import Button from 'components/core/button'
import { ProjectTemplate, TicketTemplate } from 'interfaces/project-templates'
import { request } from 'lib/api/fetch-api'
import CopyTextButton from 'lib/components/buttons/copy-text-button'
import IconButton from 'lib/components/buttons/icon-button'
import { Drawer } from 'lib/components/drawer/drawer'
import { toast } from 'lib/components/toast/toast'
import { PATHS } from 'lib/constants/paths'
import { ArrowRightToLine, LinkIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useSkillsContext } from '../request/skills/skills-provider'
import TicketTemplatesTable from './ticket-templates-table'
import Search from 'components/core/search'

export interface CreateProjectDrawerProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
  projectTemplate: ProjectTemplate
}

export default function CreateProjectDrawer({ isOpen, setOpen, projectTemplate }: CreateProjectDrawerProps) {
  const [selectedTicketTemplates, setSelectedTicketTemplates] = useState<TicketTemplate[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const isBlankProjectTemplate = projectTemplate?.name?.toLocaleLowerCase()?.includes('blank project')
  const { availableSkills } = useSkillsContext()

  const filteredTicketTemplates = searchValue
    ? projectTemplate?.ticketTemplates?.filter((template) =>
        template.skill.name.toLowerCase().includes(searchValue.toLowerCase()),
      )
    : projectTemplate?.ticketTemplates

  const createProjectMutation = useMutation({
    mutationFn: () => {
      return request({
        endpoint: 'createProject',
        body: {
          name: projectTemplate?.name,
          description: projectTemplate?.description,
          tickets: {
            skill_ids: selectedTicketTemplates.map((ticketTemplate) => ticketTemplate.skill.id),
          },
        },
      })
    },
    onSuccess: (response) => {
      toast.success('Project created')
      window.location.href = `${PATHS.REQUESTS}?tab=draft&projectId=${response.data.id}`
    },
    onError: () => {
      toast.error('Failed to create project')
    },
  })

  function handleCreateProject() {
    setIsLoading(true)
    createProjectMutation.mutate()
  }

  useEffect(() => {
    if (isBlankProjectTemplate) {
      setSelectedTicketTemplates([])
    } else {
      setSelectedTicketTemplates(
        projectTemplate?.ticketTemplates?.filter((template) =>
          availableSkills.some((skill) => skill.id === template.skill.id),
        ) || [],
      )
    }
  }, [availableSkills, isBlankProjectTemplate, projectTemplate?.ticketTemplates])

  return (
    <Drawer isOpen={isOpen} setOpen={setOpen} size="md">
      <Drawer.Header setOpen={setOpen} showCloseButton={false}>
        <div className="tw-flex tw-items-center tw-gap-4">
          <IconButton size="xs" color="transparent" onClick={() => setOpen(false)} className="tw-mr-2">
            <ArrowRightToLine className="tw-text-neutral-700" />
          </IconButton>
          <img src={projectTemplate?.imageUrl} alt={projectTemplate?.name} className="tw-h-20 tw-w-20 tw-rounded" />
          <div>
            <h4>{projectTemplate?.name}</h4>
            <p>{projectTemplate?.description}</p>
          </div>
          <div className="tw-ml-auto">
            <CopyTextButton textToCopy={window.location.href} defaultIcon={<LinkIcon />} />
          </div>
        </div>
      </Drawer.Header>
      <Drawer.Body>
        {isLoading ? (
          <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center">
            <img
              src="/images/progress/pickle-rocket-2.gif"
              alt="Pickle Rocket"
              className="tw-h-auto tw-w-1/2"
              data-testid="pickle-rocket"
            />
            <h5>Creating your project</h5>
            <p className="tw-text-sm tw-text-neutral-500">One moment please...</p>
          </div>
        ) : (
          <div>
            <TableHeader
              isBlankProjectTemplate={isBlankProjectTemplate}
              filteredTicketTemplates={filteredTicketTemplates}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <TicketTemplatesTable
              ticketTemplates={filteredTicketTemplates}
              isBlankProjectTemplate={isBlankProjectTemplate}
              selectedTicketTemplates={selectedTicketTemplates}
              setSelectedTicketTemplates={setSelectedTicketTemplates}
              availableSkills={availableSkills}
            />
            <div className="tw-sticky tw-bottom-0 tw-flex tw-w-full tw-justify-between tw-bg-white tw-py-4">
              <h5>{selectedTicketTemplates.length} requests selected</h5>
              <Button color="purple" disabled={selectedTicketTemplates.length === 0} onClick={handleCreateProject}>
                Create Project
              </Button>
            </div>
          </div>
        )}
      </Drawer.Body>
    </Drawer>
  )
}

interface TableHeaderProps {
  isBlankProjectTemplate: boolean
  filteredTicketTemplates: TicketTemplate[]
  searchValue: string
  setSearchValue: (value: string) => void
}

const TableHeader = ({
  isBlankProjectTemplate,
  filteredTicketTemplates,
  searchValue,
  setSearchValue,
}: TableHeaderProps) => {
  const header = (text) => {
    return <h5 className="tw-my-2 tw-min-w-fit">{text}</h5>
  }

  if (isBlankProjectTemplate) {
    return (
      <>
        <h4 className="tw-m-0 tw-mb-4 tw-mt-2 tw-text-xl">Select request(s) to add to this project</h4>
        <div className="tw-mb-4 tw-flex tw-items-center tw-gap-2">
          <Search
            name="create-search"
            value={searchValue}
            onChange={setSearchValue}
            placeholder="Search"
            className="tw-w-full"
          />
          {header(`${filteredTicketTemplates?.length} requests found`)}
        </div>
      </>
    )
  }

  return header(`${filteredTicketTemplates?.length} default requests`)
}
