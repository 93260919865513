import { ReactElement, ReactNode, useState } from 'react'
import { Share2 } from 'lucide-react'
import Button, { LinkButton } from 'components/core/button'
import { useRequestContext } from '../providers/request-provider'
import ConfirmationModal from 'components/elements/confirmation-modal'
import { GenAiRequest, GenAiRequestStatus } from 'lib/api/gen-ai/gen-ai-requests'
import {
  archiveTicket,
  cancelTicket,
  completeTicket,
  deleteTicket,
  DesignRequestStatus,
  duplicateTicketUrl,
  EditMode,
  submitTicket,
} from 'lib/api/tickets/tickets'
import IconButton from 'lib/components/buttons/icon-button'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { useDirectionsContext } from '../providers/timeline-directions-provider'
import { useMediaContext } from '../media/media-provider'
import { useGenAiContext } from '../providers/gen-ai-provider'
import ConfirmSendToMyDesignerModal, { ModalTypes } from './confirm-send-to-my-designer-modal'
import ShareModal from './share-modal/share-modal'
import TicketFeedbackProvider, { RatingModalTicket, useTicketFeedbackContext } from 'providers/ticket-feedback-provider'
import { RatingsSteps } from 'interfaces/ticket_feedback'
import ThumbRateRequestModal from 'components/core/thumb-rate-request-modal'
import { toast } from 'lib/components/toast/toast'
import { useAnnotationsContext } from 'components/pages/request/providers/annotations-provider'
import { DownloadAllAssetsButton } from 'lib/components/download-assets-button/download-assets-button'
import { revisionsCanBeSentToDesigner } from 'lib/ticket/utils'

const classNames = {
  button: 'tw-inline-block tw-ml-4 tw-mb-2',
  share: 'tw-inline-block tw-align-middle tw-ml-2',
  kebab: 'tw-inline-block tw-ml-2',
  secondRow: 'tw-inline-block',
}

function dismissAllPendingGenAiRequests(
  genAiRequests: GenAiRequest[],
  removeAndDismiss: (id: number) => void,
  removeAndCancel: (id: number) => void,
) {
  if (genAiRequests?.length > 0) {
    genAiRequests.forEach((request) => {
      if (request.status === GenAiRequestStatus.Completed || request.status === GenAiRequestStatus.Error) {
        removeAndDismiss(request.id)
      } else {
        removeAndCancel(request.id)
      }
    })
  }
}

function SendToMyDesignerButton({ children }: { children: ReactNode }): ReactElement {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationModalType, setConfirmationModalType] = useState<ModalTypes>('general')
  const { setShowValidationErrors, invalidFields, ticket } = useRequestContext()
  const { areDirectionsValid, setShowDirectionErrors } = useDirectionsContext()
  const { genAiRequests, removeAndDismiss, removeAndCancel } = useGenAiContext()
  const { areAnnotationsValid } = useAnnotationsContext()
  const { files } = useMediaContext()

  async function submit() {
    dismissAllPendingGenAiRequests(genAiRequests, removeAndDismiss, removeAndCancel)

    try {
      await submitTicket(ticket.id)

      window.location.assign('/requests')
    } catch (e) {
      console.error('Error submitting ticket', e)
      toast.error(
        ticket?.status === DesignRequestStatus.draft
          ? 'Oops! Something went wrong submitting request. Please try again'
          : 'Oops! Something went wrong sending updates. Please try again',
      )
    }
  }

  function hasInvalidFields() {
    const annotationsValid = areAnnotationsValid()
    if (ticket?.status === DesignRequestStatus.draft) {
      return invalidFields.length > 0 || !areDirectionsValid || !annotationsValid
    } else {
      // If a revision doesn't have sizes, or formats allow submission
      return (
        !annotationsValid ||
        !areDirectionsValid ||
        invalidFields.some((field) => field !== 'sizes' && field !== 'formats')
      )
    }
  }

  function pendingAnnotations() {
    const annotations = files
      .filter((file) => file.ticketVersion === ticket?.currentVersion)
      .flatMap((file) => {
        if (file.isExtractable) {
          return file.extractedPages.flatMap((page) => page.annotations)
        }
        return file.annotations
      })
    return annotations.filter((annotation) => annotation.status === 'review')
  }

  function pendingGenAiRequests() {
    return genAiRequests?.filter((request) => request.status !== GenAiRequestStatus.Error)
  }

  async function handleSubmit() {
    const pendingUpdates = {
      genAi: pendingGenAiRequests(),
      pendingAnnotations: pendingAnnotations(),
    }

    if (hasInvalidFields()) {
      setShowValidationErrors(true)
      if (!areDirectionsValid || !areAnnotationsValid()) {
        setShowDirectionErrors(true)
      }
    } else if (pendingUpdates.genAi.length > 0 && pendingUpdates.pendingAnnotations.length > 0) {
      setConfirmationModalType('general')
      setShowConfirmationModal(true)
    } else if (pendingUpdates.pendingAnnotations.length > 0) {
      setConfirmationModalType('pending-annotations')
      setShowConfirmationModal(true)
    } else if (pendingUpdates.genAi.length > 0) {
      setConfirmationModalType('gen-ai')
      setShowConfirmationModal(true)
    } else {
      await submit()
    }
  }

  return (
    <>
      <Button
        type="button"
        onClick={handleSubmit}
        color="purple"
        className={classNames.button}
        data-testid="submit-button"
      >
        {children}
      </Button>
      <ConfirmSendToMyDesignerModal
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        confirm={submit}
        type={confirmationModalType}
        pendingAnnotationsLength={pendingAnnotations().length}
      />
    </>
  )
}

function MarkAsCompleteModals({
  showConfirmationModal,
  setShowConfirmationModal,
  showCompleteModal,
  setShowCompleteModal,
}: {
  showConfirmationModal: boolean
  setShowConfirmationModal: (value: boolean) => void
  showCompleteModal: boolean
  setShowCompleteModal: (value: boolean) => void
}) {
  const { ticket } = useRequestContext()
  const { genAiRequests, removeAndDismiss, removeAndCancel } = useGenAiContext()
  const { setStep, toggleFeedbackModalVisibility, setRatingModalTicket } = useTicketFeedbackContext()

  async function markAsComplete() {
    dismissAllPendingGenAiRequests(genAiRequests, removeAndDismiss, removeAndCancel)

    try {
      await completeTicket(ticket.id)
      setShowConfirmationModal(false)
      setShowCompleteModal(false)
      setRatingModalTicket(ticket as unknown as RatingModalTicket)
      setStep(RatingsSteps.Rate)
      toggleFeedbackModalVisibility(true)
    } catch (e) {
      toast.error('Oops! Something went wrong marking complete. Please try again')
      console.error('Error marking ticket as complete', e)
    }
  }

  function onModalClose() {
    setShowConfirmationModal(false)
    setShowCompleteModal(false)
    window.location.assign('/requests')
  }

  return (
    <>
      <ConfirmSendToMyDesignerModal
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        confirm={markAsComplete}
        type="general"
        confirmText="Mark request as complete anyway"
      />
      <ConfirmSendToMyDesignerModal
        open={showCompleteModal}
        setOpen={setShowCompleteModal}
        confirm={markAsComplete}
        type="complete"
        confirmText="Yes, Mark As Complete"
      />
      <ThumbRateRequestModal onModalClose={onModalClose} />
    </>
  )
}

function ShareContainer() {
  const [open, setOpen] = useState(false)
  const { canShare } = useRequestContext()

  if (!canShare) return null

  return (
    <div className={classNames.share}>
      <IconButton color="secondary" onClick={() => setOpen(true)}>
        <Share2 className="lu-md lu-light" />
      </IconButton>
      <ShareModal open={open} setOpen={setOpen} />
    </div>
  )
}

export default function HeaderActions(): ReactElement {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showCompleteModal, setShowCompleteModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const { genAiRequests } = useGenAiContext()

  const { ability, editMode, showRevisions, ticket } = useRequestContext()
  const cancelText = ticket?.status === DesignRequestStatus.draft ? 'Delete' : 'Cancel'
  const isClosed = [DesignRequestStatus.canceled, DesignRequestStatus.archived, DesignRequestStatus.completed].includes(
    ticket?.status,
  )

  async function handleConfirmCancelClick() {
    try {
      let reassignUrl
      if (ticket?.status === DesignRequestStatus.draft) {
        await deleteTicket(ticket.id)
        reassignUrl = '/requests?tab=draft'
      } else {
        await cancelTicket(ticket.id)
        reassignUrl = '/requests'
      }
      window.location.assign(reassignUrl)
    } catch (e) {
      if (ticket?.status === DesignRequestStatus.draft) {
        toast.error('Oops! Something went wrong deleting. Please try again')
        console.error('Error deleting ticket', e)
      } else {
        toast.error('Oops! Something went wrong cancelling. Please try again')
        console.error('Error cancelling ticket', e)
      }
    }
  }

  function handleCancelClick() {
    setIsModalVisible(true)
  }

  function handleNoClick() {
    setIsModalVisible(false)
  }

  function redirectUrl() {
    if (ticket?.status === DesignRequestStatus.draft) {
      return '/requests?tab=draft'
    }
    return '/requests'
  }

  async function handleArchiveClick() {
    try {
      await archiveTicket(ticket.id)
      window.location.assign('/requests?tab=archived')
    } catch (e) {
      toast.error('Oops! Something went wrong archiving. Please try again')
      console.error('Error archiving ticket', e)
    }
  }

  function canMarkAsComplete() {
    const isInReview = ticket?.status === DesignRequestStatus.review
    // ECR or QR Failed
    const isProcessingAndOnDeck = editMode === EditMode.processing && ticket?.status === DesignRequestStatus.onDeck
    const isOnDeckAndDelivered = ticket?.status === DesignRequestStatus.onDeck && ticket?.currentVersion
    const isAttentionAndDelivered = ticket?.status === DesignRequestStatus.attention && ticket?.currentVersion
    const hasPermission = ability?.markComplete

    return hasPermission && (isInReview || isProcessingAndOnDeck || isOnDeckAndDelivered || isAttentionAndDelivered)
  }

  function canCancel() {
    return editMode !== EditMode.complete && ability?.cancel
  }

  function canDuplicate() {
    return ability?.duplicate
  }

  function canArchive() {
    return ability?.markArchived && editMode === EditMode.complete && ticket?.status !== DesignRequestStatus.archived
  }

  async function handleMarkAsCompleteClick() {
    if (ticket.meta.pendingUpdates > 0) {
      setShowConfirmationModal(true)
    } else if (genAiRequests?.filter((request) => request.status !== GenAiRequestStatus.Error).length) {
      setShowConfirmationModal(true)
    } else {
      setShowCompleteModal(true)
    }
  }

  const hasEdits = revisionsCanBeSentToDesigner(ticket, editMode)

  return (
    <>
      {ticket?.status === DesignRequestStatus.draft && (
        <SendToMyDesignerButton>Send to my designer</SendToMyDesignerButton>
      )}
      {hasEdits && (
        <SendToMyDesignerButton>
          <>
            <span className="tw-mr-2 tw-rounded-full tw-bg-white tw-px-2 tw-text-neutral-800">
              {ticket.meta.pendingUpdates}
            </span>
            Send updates to my designer
          </>
        </SendToMyDesignerButton>
      )}
      {canArchive() && (
        <Button
          type="button"
          onClick={handleArchiveClick}
          color={hasEdits ? 'lightGray' : 'purple'}
          className={classNames.button}
          data-testid="submit-button"
        >
          Archive
        </Button>
      )}

      {canDuplicate() && isClosed && (
        <LinkButton className={classNames.button} color="purple" url={duplicateTicketUrl(ticket.id)} data-method="POST">
          Duplicate
        </LinkButton>
      )}

      {canMarkAsComplete() && !hasEdits && (
        <Button
          type="button"
          color={revisionsCanBeSentToDesigner(ticket, editMode) ? 'lightGray' : 'purple'}
          className={classNames.button}
          onClick={handleMarkAsCompleteClick}
          data-classid="complete-button"
        >
          Mark As Complete
        </Button>
      )}
      <div className={classNames.secondRow}>
        <LinkButton url={redirectUrl()} color="lightGray" className={classNames.button}>
          {editMode !== EditMode.complete && 'Save and'} Close
        </LinkButton>
        <ShareContainer />
        <div className={classNames.kebab}>
          <IconFlyoutMenu color="transparent" icon={['far', 'ellipsis-v']}>
            {showRevisions && (
              <DownloadAllAssetsButton
                version={ticket?.currentVersion}
                buttonColor="transparent"
                buttonText="Download Latest Assets"
                className="tw-w-full tw-gap-1 tw-rounded-none tw-border-0 tw-px-5 tw-py-2 tw-text-sm tw-text-neutral-800 hover:tw-bg-neutral-100 hover:tw-text-cornflower-600"
              />
            )}
            {canDuplicate() && !isClosed && (
              <IconFlyoutMenu.Link url={duplicateTicketUrl(ticket.id)} target="_self" data-method="POST">
                Duplicate
              </IconFlyoutMenu.Link>
            )}
            {canCancel() && <IconFlyoutMenu.Button onClick={handleCancelClick}>{cancelText}</IconFlyoutMenu.Button>}
            {hasEdits && (
              <IconFlyoutMenu.Button onClick={handleMarkAsCompleteClick}>Mark As Complete</IconFlyoutMenu.Button>
            )}
          </IconFlyoutMenu>
        </div>
      </div>
      {canCancel() && (
        <ConfirmationModal
          title={`${cancelText} Request`}
          message={`Are you sure you want to ${cancelText.toLowerCase()} ${ticket?.subject}?`}
          confirmBtnText={`Yes, ${cancelText}`}
          confirmAction={handleConfirmCancelClick}
          cancelAction={handleNoClick}
          visible={isModalVisible}
        />
      )}
      <TicketFeedbackProvider>
        <MarkAsCompleteModals
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          showCompleteModal={showCompleteModal}
          setShowCompleteModal={setShowCompleteModal}
        />
      </TicketFeedbackProvider>
    </>
  )
}
