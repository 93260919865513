import Banner from 'lib/components/banner/banner'
import { FolderOpenIcon } from 'lucide-react'
import { ReactElement } from 'react'

function ProjectTemplates(): ReactElement {
  return (
    <div className="tw-mt-[var(--header-height,0px)] tw-h-[calc(100vh-var(--header-height,0px))] tw-w-full tw-p-5">
      <h2 className="tw-m-0 tw-flex tw-items-center tw-gap-1 tw-p-0 tw-text-xl">
        <FolderOpenIcon className="tw-size-6" />
        <span>Projects</span>
      </h2>
      <div className="tw-mt-2 tw-flex tw-flex-col tw-gap-2">
        <Banner type="warning">
          Projects is currently in development, some features may not work as expected or be available.
        </Banner>
      </div>
    </div>
  )
}

export default ProjectTemplates
