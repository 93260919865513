import { ReactElement, useEffect, useMemo, useState } from 'react'
import { CheckCircle2Icon, Download, FolderArchiveIcon, Link, RefreshCw } from 'lucide-react'
import { GenAiResultTypes, getGenAiResultType } from 'lib/api/gen-ai/gen-ai-requests'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import Tooltip from 'lib/components/tooltip/tooltip'
import { LoadingScreen } from '../../requests/empty-screens'
import CreateAssistOriginalPromptModal from '../ai/modals/create-assist-original-prompt-modal'
import { EditAssistOriginalPromptModal } from '../ai/modals/edit-assist-results-modal'
import { useAnnotationsContext } from '../providers/annotations-provider'
import { useMediaContext } from './media-provider'
import MultipageNavigation from 'lib/components/multipage/multipage-navigation'
import MediaViewer from './media-viewer'
import Banner from 'lib/components/banner/banner'
import MediaDrawer from './media-drawer'
import IconButton from 'lib/components/buttons/icon-button'
import { toast } from 'lib/components/toast/toast'
import Button from 'components/core/button'
import FileDownloadModal from './file-download-modal'
import { getShareLinkUrl } from 'lib/util/file/file'
import { useS3PreviewImage } from 'lib/hooks/useS3PreviewImage'
import { useMutation } from '@tanstack/react-query'
import { request } from 'lib/api/fetch-api'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import FileType from 'lib/components/file-type/file-type'
import { CustomerState } from 'lib/api/ticket-files/ticket-files'

interface MediaContainerProps {
  show: boolean
  showOverflow: boolean
  isColab?: boolean
}

const classNames = {
  mediaView: {
    aside: 'tw-flex-none tw-relative tw-duration-500 tw-bg-gray-50 tw-h-full',
    container: 'tw-h-full tw-border-gray-200 tw-border-0 tw-border-r tw-border-l tw-border-solid',
    controls: 'tw-text-right',
    figure: 'tw-w-full tw-text-center tw-p-0 tw-m-0 tw-relative',
    ticketFileName: 'tw-neutral-800 tw-flex tw-gap-2 tw-whitespace-nowrap tw-items-center',
  },
}

const styles = {
  requestMainCollapsed: {
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-out',
    width: '0px',
  },

  requestMainExpanded: {
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-in',
  },
}

const headerHeight = 100

export default function MediaContainer({ show, showOverflow, isColab }: MediaContainerProps): ReactElement {
  const {
    extractedPreviewIndex,
    extractedFilesPollingFailed,
    retryExtractingPages,
    selectedFile,
    setExtractedPreviewIndex,
    zipFile,
    refreshTicketFiles,
  } = useMediaContext()
  const [showPromptModal, setShowPromptModal] = useState<boolean>(false)
  const [isTaggingDrawerOpen, setIsTaggingDrawerOpen] = useState<boolean>(false)
  const [isFileExportModalOpen, setIsFileExportModalOpen] = useState<boolean>(false)
  const { isCollaboratorView } = useAnnotationsContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  const { mutate: updateTicketFile } = useMutation({
    mutationFn: ({
      ticketId,
      ticketFileId,
      customerState,
    }: {
      ticketId: number
      ticketFileId: number
      customerState: CustomerState
    }) =>
      request({
        endpoint: 'updateTicketFile',
        query: { ticket_id: ticketId, ticket_file_id: ticketFileId, customer_state: customerState },
      }),
    onSuccess: () => {
      refreshTicketFiles()
    },
    onError: () => {
      toast.error('There was an error marking this file as approved, please try again')
    },
  })

  const currentTicketFile = useMemo(() => {
    if (selectedFile?.isExtractable) {
      if (extractedPreviewIndex === -1 || !selectedFile.extractedPages?.length) {
        return null
      }
      const index = Math.min(extractedPreviewIndex, selectedFile.extractedPages.length)
      return selectedFile.extractedPages[index]
    }
    return selectedFile
  }, [selectedFile, extractedPreviewIndex])

  const bannerHeight = selectedFile?.preview ? 80 : 0

  const genAiResultType: GenAiResultTypes = useMemo(() => getGenAiResultType(selectedFile), [selectedFile])

  const previewUrl = useS3PreviewImage(selectedFile)
  const downloadUrl = selectedFile?.downloadUrl

  const [shareLinkUrl, setShareLinkUrl] = useState<string | null>(null)

  useEffect(() => {
    const fetchShareLinkUrl = async () => {
      const url = await getShareLinkUrl(selectedFile, previewUrl)
      setShareLinkUrl(url)
    }
    fetchShareLinkUrl()
  }, [selectedFile, previewUrl])

  const stockpressEnabled = isFeatureFlagEnabled('stockpress')

  return (
    <>
      {selectedFile && !isCollaboratorView && !selectedFile.preview && (
        <FileDownloadModal
          isFileExportModalOpen={isFileExportModalOpen}
          setIsFileExportModalOpen={setIsFileExportModalOpen}
          currentTicketFile={selectedFile}
          downloadUrl={downloadUrl}
        />
      )}
      <aside
        className={`${classNames.mediaView.aside} ${showOverflow ? '' : 'tw-overflow-hidden'}`}
        style={show && !!selectedFile ? styles.requestMainExpanded : styles.requestMainCollapsed}
      >
        {selectedFile && (
          <div className={`${classNames.mediaView.container} ${showOverflow ? '' : 'tw-overflow-y-auto'}`}>
            {genAiResultType === GenAiResultTypes.CreateAssist && (
              <CreateAssistOriginalPromptModal
                open={showPromptModal}
                setOpen={() => setShowPromptModal(false)}
                requestId={selectedFile.imageableId}
              />
            )}
            {genAiResultType === GenAiResultTypes.EditAssist && (
              <EditAssistOriginalPromptModal
                open={showPromptModal}
                setOpen={() => setShowPromptModal(false)}
                requestId={selectedFile.imageableId}
              />
            )}
            <header className="tw-flex tw-items-center tw-justify-between tw-break-all tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-bg-white tw-px-4 tw-py-6">
              <div className="tw-flex tw-items-center tw-overflow-hidden">
                <FileType className="tw-mr-2" fileName={selectedFile.name} />
                <strong className="tw-truncate">
                  {selectedFile.name}
                  {zipFile && (
                    <>
                      {' '}
                      <span className="tw-ml-2 tw-text-xs tw-font-normal tw-text-neutral-600">
                        <FolderArchiveIcon className="tw-mr-1 tw-h-4 tw-w-4" /> via {zipFile.name}
                      </span>
                    </>
                  )}
                </strong>
                {!isCollaboratorView && !selectedFile.preview && shareLinkUrl && (
                  <Tooltip content="Copy Share Link" direction="up">
                    <IconButton
                      color="transparent"
                      onClick={() => {
                        navigator.clipboard.writeText(shareLinkUrl)
                        toast.success('File URL copied to clipboard! This link will expire in 7 days.')
                      }}
                      className="tw-mx-1"
                      dataTestid="copy-share-link"
                    >
                      <Link className="lu-light lu-sm" />
                    </IconButton>
                  </Tooltip>
                )}
                {stockpressEnabled && (
                  <Button
                    color={selectedFile.customerState === CustomerState.Approved ? 'softGreen' : 'neutral'}
                    className="tw-truncate"
                    onClick={() => {
                      const newState =
                        selectedFile.customerState === CustomerState.Approved
                          ? CustomerState.Concept
                          : CustomerState.Approved

                      updateTicketFile({
                        ticketId: selectedFile.ticketId,
                        ticketFileId: selectedFile.id,
                        customerState: newState,
                      })
                    }}
                  >
                    <CheckCircle2Icon className="tw-mr-1 tw-h-4 tw-w-4" />
                    {selectedFile.customerState === CustomerState.Approved ? 'Approved' : 'Mark approved'}
                  </Button>
                )}
              </div>

              {selectedFile.isExtractable && selectedFile.extractedPages.length !== 0 && (
                <MultipageNavigation
                  index={extractedPreviewIndex}
                  count={selectedFile.extractedPages.length}
                  setIndex={setExtractedPreviewIndex}
                />
              )}
              <div className="tw-flex tw-items-center">
                {!isCollaboratorView && !selectedFile.preview && downloadUrl && (
                  <Tooltip content="Download" direction="up">
                    <IconButton
                      color="transparent"
                      onClick={() => {
                        setIsFileExportModalOpen(true)
                      }}
                      className="tw-mx-1 tw-p-3"
                      dataTestid="download-file"
                    >
                      <Download className="lu-light lu-sm" />
                    </IconButton>
                  </Tooltip>
                )}
                {!isColab && (
                  <IconFlyoutMenu color="transparent" icon={['far', 'ellipsis-v']}>
                    {stockpressEnabled && <IconFlyoutMenu.Button onClick={() => {}}>Export</IconFlyoutMenu.Button>}
                    {genAiResultType !== null && (
                      <IconFlyoutMenu.Button onClick={() => setShowPromptModal(true)}>
                        View Original Prompt
                      </IconFlyoutMenu.Button>
                    )}
                    <IconFlyoutMenu.Button onClick={() => setIsTaggingDrawerOpen(true)}>
                      View details
                    </IconFlyoutMenu.Button>
                  </IconFlyoutMenu>
                )}
                {currentTicketFile && !isColab && (
                  <MediaDrawer
                    isOpen={isTaggingDrawerOpen}
                    setIsOpen={setIsTaggingDrawerOpen}
                    file={currentTicketFile}
                  />
                )}
              </div>
            </header>
            {selectedFile?.preview && (
              <div className="tw-">
                <Banner type="warning" heading="This is a preview. Your designer will continue working on this soon." />
              </div>
            )}
            {!currentTicketFile && !extractedFilesPollingFailed && (
              <div className="tw-text-center">
                <LoadingScreen />
              </div>
            )}
            {!currentTicketFile && extractedFilesPollingFailed && (
              <div className="tw-mx-10 tw-my-4 tw-text-center">
                <img alt="Pickle Error Hole" src="/images/states/error-hole.png" height={200} width={200} />
                <h5>Uh oh! We couldn’t load all your pages</h5>
                <p>
                  It seems there was an issue processing your attachment. Please try again. If the issue persists, feel
                  free to reach out to our Customer Support team at{' '}
                  <a href="mailto:help@designpickle.com">help@designpickle.com</a> for assistance.
                </p>
                <Button color="purple" onClick={() => retryExtractingPages()}>
                  <RefreshCw className="lu-md" /> Retry
                </Button>
              </div>
            )}
            {currentTicketFile && (
              <figure
                className={classNames.mediaView.figure}
                style={{ height: `calc(100% - ${headerHeight + bannerHeight}px)` }}
              >
                <MediaViewer currentTicketFile={currentTicketFile} selectedFile={selectedFile} />
              </figure>
            )}
          </div>
        )}
      </aside>
    </>
  )
}
