import Button from 'components/core/button'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { isValidHexColor } from 'lib/util/color'
import { BrandPayload, createBrand, deleteBrand, updateBrand } from 'lib/api/custom-branding/custom-branding'
import { CustomBrandingUrlSettings } from './custom-branding-url-settings'
import { CustomBrandingColorSettings } from './custom-branding-color-settings'
import { CustomBrandingLogoSettings } from './custom-branding-logo-settings'
import { SquareArrowOutUpRight } from 'lucide-react'
import { CustomBrandingPageProps, CustomBrandingSettings } from './custom-branding-page'
import CustomBrandingUrlWarningModal from './custom-branding-url-warning-modal'
import { toast } from 'lib/components/toast/toast'
import { getPresignedUrl } from 'lib/api/file-urls/file-urls'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'

export interface FormState {
  logo?: {
    value: string
    error: string[]
  }
  s3_logo_filename?: {
    value: string
    error: string[]
  }
  primary_color?: {
    value: string
    error: string[]
  }
  url?: {
    value: string
    error: string[]
  }
}

export interface UpdateFormState {
  (key: string, value: string, error?: string | null): void
}

export default function CustomBranding({
  settings,
  company,
  customBranding,
  customBrandingStatus,
  customBrandingPlanId,
  hasWhiteLabelSubscription,
  canSubscribeToWhiteLabel,
  isPowerCompany,
}: CustomBrandingPageProps) {
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const showS3Links = isFeatureFlagEnabled('show_s3_links')

  const initialState = useMemo(() => {
    return {
      ...customBranding,
      logo: showS3Links || customBranding?.s3LogoFilename ? '' : customBranding?.logo,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [localCustomBranding, setLocalCustomBranding] = useState<CustomBrandingSettings>(initialState)

  const getInitialFormState = useCallback(
    () => ({
      logo: {
        value: showS3Links ? (localCustomBranding?.s3Logo ?? null) : (localCustomBranding?.logo ?? null),
        error: null,
      },
      s3_logo_filename: {
        value: localCustomBranding?.s3LogoFilename ?? null,
        error: null,
      },
      primary_color: {
        value: isValidHexColor(localCustomBranding?.primaryColor) ? localCustomBranding?.primaryColor : '#576EE7',
        error: null,
      },
      url: {
        value: localCustomBranding?.url ?? '',
        error: null,
      },
    }),
    [localCustomBranding, showS3Links],
  )
  const [formState, setFormState] = useState(getInitialFormState())
  const resetState = useCallback(() => {
    setFormState(getInitialFormState())
  }, [getInitialFormState])
  const updateFormState = useCallback((key: string, value: string, error: string = null) => {
    setFormState((prevState) => ({ ...prevState, [key]: { value, error } }))
  }, [])

  const [hasChanges, setHasChanges] = useState(false)
  const [showUrlWarningModal, setShowUrlWarningModal] = useState(false)

  useEffect(() => {
    const initialState = getInitialFormState()
    setHasChanges(
      Object.keys(formState).some(
        (key) => formState[key].value !== initialState[key].value && (key === 'logo' ? !showS3Links : true),
      ),
    )
  }, [formState, getInitialFormState, showS3Links])

  useEffect(() => {
    resetState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCustomBranding])

  useEffect(() => {
    const fetchPresignedUrl = async () => {
      if (formState.s3_logo_filename?.value) {
        const presignedUrl = await getPresignedUrl(formState.s3_logo_filename.value)
        updateFormState('logo', presignedUrl)
      }
    }
    fetchPresignedUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.s3_logo_filename])

  const shouldDeleteCustomBranding = useCallback((currentState, initialState) => {
    const hasChangedExistingUrl = !!(currentState?.url?.value !== initialState?.url?.value && initialState?.url?.value)
    const hasRemovedExistingUrl = !currentState?.url?.value && !!initialState?.url?.value
    return hasChangedExistingUrl || hasRemovedExistingUrl
  }, [])

  async function handleSave() {
    const initialState = getInitialFormState()
    if (shouldDeleteCustomBranding(formState, initialState)) {
      setShowUrlWarningModal(true)
    } else {
      await saveCustomBranding()
    }
  }

  async function saveCustomBranding() {
    const payload = Object.entries(formState).reduce((acc, [key, { value }]) => {
      acc[key] = value
      return acc
    }, {}) as BrandPayload

    try {
      const response = await (async () => {
        const initialState = getInitialFormState()
        if (shouldDeleteCustomBranding(formState, initialState)) {
          return await deleteBrand(localCustomBranding.id)
        }

        if (localCustomBranding?.id) {
          return await updateBrand(localCustomBranding.id, payload)
        }

        return await createBrand(payload)
      })()

      setLocalCustomBranding(response?.data)

      toast.success('White Label Settings Saved')
    } catch (error) {
      if (error.response?.data?.errors) {
        const { errors } = error.response.data
        Object.keys(errors).forEach((key) => {
          if (key in formState) {
            const errorMessage = errors[key]
              .map((err) => `${key.charAt(0).toUpperCase() + key.slice(1)} ${err}`)
              .join('. ')
              .concat('.')
            updateFormState(key, formState[key].value, errorMessage)
          }
        })
      } else {
        toast.error('Something went wrong, please try again.')
      }
    }
  }

  function WhiteLabelPromotionContent() {
    if (isPowerCompany) {
      return (
        <p className="m-0">
          Please contact your Account Executive or{' '}
          <a href="mailto:help@designpickle.com" className="tw-font-bold">
            help@designpickle.com
          </a>{' '}
          for support in managing your plans.
        </p>
      )
    }
    if (canSubscribeToWhiteLabel) {
      return (
        <Button
          color="purple"
          data-title="Subscribe to White Label"
          data-toggle="ajax-modal"
          data-url={`/pickle_subscriptions/new?company_id=${company?.id}&pickle_plan_id=${customBrandingPlanId}`}
          className="ajax-modal-link tw-self-start"
        >
          Activate white label
        </Button>
      )
    }
    return (
      <p className="m-0">
        Please reach out to your account administrator to enable this add-on. For questions, contact{' '}
        <a href="mailto:help@designpickle.com" className="tw-font-bold">
          help@designpickle.com
        </a>
      </p>
    )
  }

  return (
    <>
      {!hasWhiteLabelSubscription && (
        <div className="tw-mt-4 tw-flex tw-flex-row tw-gap-4 tw-bg-cornflower-50 tw-p-6 tw-shadow">
          <img className="tw-w-48" src="/images/white_labeling/whiteLabel_banner.png" />
          <div className="tw-flex tw-flex-col tw-gap-3">
            <h4 className="tw-m-0 tw-p-0">White-label our Share & Review tool </h4>
            <p className="tw-m-0 tw-p-0 tw-text-base tw-text-peppercorn-900">
              Add your own logo, brand color, and custom domain to our Share & Review tool so your customers see your
              brand instead of ours! Read more on our{' '}
              <a
                href="https://help.designpickle.com/en/articles/9487688-share-review-how-to-use-white-label"
                target="_blank"
                rel="noreferrer"
                className="tw-inline-flex tw-items-center tw-gap-1 tw-font-bold tw-text-cornflower-500"
              >
                knowledge base
                <SquareArrowOutUpRight className="lu-sm" />
              </a>
            </p>
            <WhiteLabelPromotionContent />
          </div>
        </div>
      )}
      <div className={!hasWhiteLabelSubscription ? 'tw-pointer-events-none tw-opacity-50 tw-saturate-0 tw-filter' : ''}>
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-4 tw-py-6">
          <div className="tw-flex tw-flex-row tw-items-end tw-gap-4">
            <h3 className="tw-my-0">White Label</h3>
            <span className="tw-font-medium">
              Learn more on our{' '}
              <a
                href="https://help.designpickle.com/en/articles/9487688-share-review-how-to-use-white-label"
                target="_blank"
                rel="noreferrer"
              >
                knowledge base <SquareArrowOutUpRight className="lu-sm" />
              </a>
            </span>
          </div>
          <div className="tw-flex tw-flex-col tw-items-end tw-gap-2">
            <div className="tw-flex tw-flex-row tw-gap-2">
              {hasChanges && (
                <Button
                  color="lightGray"
                  onClick={() => {
                    resetState()
                  }}
                >
                  Discard Changes
                </Button>
              )}
              <Button color="purple" onClick={async () => await handleSave()} disabled={!hasChanges}>
                Save
              </Button>
            </div>
            <div className="tw-text-right tw-text-flushpink-500">
              {Object.entries(formState).map(([key, { error }]) => error && <div key={key + error}>{error}</div>)}
            </div>
          </div>
        </div>
        <div className="tw-rounded tw-bg-white tw-p-6 tw-shadow-sm">
          <div className="tw-flex tw-flex-col tw-gap-12">
            <CustomBrandingUrlSettings
              formState={formState}
              updateFormState={updateFormState}
              customBranding={localCustomBranding}
              customBrandingStatus={customBrandingStatus}
            />
            <div className="tw-flex tw-flex-col tw-items-baseline tw-gap-4">
              <div>
                <h4>Add your brand color and logo</h4>
                <p className="tw-mb-0">
                  Upload your logo and your brand color to customize all Share & Review pages you send to your clients.
                </p>
              </div>
              <div className="tw-flex tw-w-full tw-flex-row tw-items-start tw-gap-8">
                <div className="tw-flex tw-w-40 tw-flex-1 tw-flex-col tw-items-start tw-gap-4">
                  <CustomBrandingColorSettings formState={formState} updateFormState={updateFormState} />
                  <CustomBrandingLogoSettings
                    formState={formState}
                    updateFormState={updateFormState}
                    apiKey={settings.filestackApiKey}
                  />
                </div>
                <div className="tw-flex-1">
                  <label>Where your color and logo will go</label>
                  <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-4 tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-p-4">
                    <img className="tw-max-w-lg" src="/images/white_labeling/whiteLabel_authPreview.png" />
                    <img className="tw-max-w-lg" src="/images/white_labeling/whiteLabel_appPreview.png" />
                  </div>
                  <p className="tw-mt-2 tw-block">
                    Your brand color and logo will be used to update the login page, main review page and elsewhere in
                    the share & review experience
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomBrandingUrlWarningModal
        oldDomain={getInitialFormState().url?.value}
        newDomain={formState.url?.value}
        open={showUrlWarningModal}
        setOpen={setShowUrlWarningModal}
        callback={async () => {
          setShowUrlWarningModal(false)
          await saveCustomBranding()
        }}
      />
    </>
  )
}
