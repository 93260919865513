import { ColumnDef, flexRender, getCoreRowModel, RowSelectionState, useReactTable } from '@tanstack/react-table'
import Button from 'components/core/button'
import Checkbox from 'lib/components/checkbox/checkbox'
import SearchInput from 'lib/components/search-input/search-input'
import { useEffect, useMemo, useState } from 'react'
import { SubscriptionTypeIcon } from '../deliverable-type'
import { SubscriptionType } from 'interfaces/project-templates'
import { useMutation, useQuery } from '@tanstack/react-query'
import { request } from 'lib/api/fetch-api'
import { Skeleton } from 'lib/components/skeleton/skeleton'
import { toast } from 'sonner'
import { SkillCategorySkill } from 'lib/api/skills/skills'
import { cn } from 'lib/util/cn'

const CreateDeliverablesTableTitleCell = ({ skill }: { skill: SkillCategorySkill }) => (
  <div className="tw-flex tw-items-center tw-gap-2">
    <img src={skill.imageUrl} alt={skill.name} className="tw-h-16 tw-w-16 tw-rounded-t-lg tw-object-cover" />
    <div className="tw-flex tw-flex-col tw-justify-center tw-gap-1">
      <div className="tw-font-bold">{skill.name}</div>
      <div>{skill.skillCategoryName}</div>
    </div>
  </div>
)

interface CreateDeliverablesTableProps {
  onFinished: () => void
  onCancel: () => void
  projectId: number
}

const CreateDeliverablesTable = ({ onFinished, onCancel, projectId }: CreateDeliverablesTableProps) => {
  const [searchValue, setSearchValue] = useState('')
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const createAndAddDeliverables = () => {
    const skillIds = Object.keys(table.getState().rowSelection).map((skillId) => Number.parseInt(skillId))
    bulkCreateMutation.mutate({ skillIds })
  }

  const columns: ColumnDef<SkillCategorySkill>[] = [
    {
      id: 'select-col',
      cell: ({ row, table }) => (
        <Checkbox
          className="tw-m-4"
          isChecked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onClick={(e) => {
            row.getToggleSelectedHandler()(e)
            table.setGlobalFilter(row.original.name)
          }}
        />
      ),
      size: 8,
    },
    {
      id: 'title',
      header: 'Title',
      cell: (props) => <CreateDeliverablesTableTitleCell skill={props.row.original} />,
    },
    {
      id: 'type',
      header: 'Request Type',
      accessorKey: 'subscriptionType',
      cell: (props) => <SubscriptionTypeIcon type={props.cell.getValue() as SubscriptionType} />,
    },
  ]

  const { data, isLoading, error } = useQuery({
    queryKey: ['skills'],
    queryFn: () => request({ endpoint: 'getSkills' }),
  })

  const bulkCreateMutation = useMutation({
    mutationFn: ({ skillIds }: { skillIds: number[] }) => {
      return request({
        endpoint: 'bulkCreateTickets',
        body: {
          skill_ids: skillIds,
          project_id: projectId,
        },
      })
    },
    onSuccess: () => {
      onFinished()
    },
    onError: (e) => {
      console.error('Error creating tasks', e)
      toast.error('Failed to create tasks')
    },
  })

  const tableData = useMemo(
    () =>
      data
        ?.flatMap((category) => category.skills)
        .filter((skill) => skill.name.toLowerCase().includes(searchValue.toLowerCase())) ?? [],
    [data, searchValue],
  )

  const table = useReactTable({
    columns,
    data: tableData,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id.toString(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    if (error) {
      toast.error('Failed to fetch skills')
      console.error('Failed to fetch skills', error)
    }
  }, [error])

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <SearchInput value={searchValue} onChange={setSearchValue} placeholder="Search requests" />
      <h5 className="tw-m-0 tw-p-0">{tableData.length} requests found</h5>
      <div>
        <table className="tw-w-full">
          <thead>
            <tr className="tw-sticky tw--top-4 tw-z-10 tw-h-12 tw-bg-peppercorn-50">
              {table.getFlatHeaders().map((header) => (
                <th
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                  className="tw-font-medium tw-uppercase tw-text-black"
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={cn('tw-h-24 tw-border-s-2 tw-border-solid tw-border-peppercorn-100 tw-p-2', {
                  'tw-bg-cornflower-50': row.getIsSelected(),
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading && <Skeleton className="tw-mb-4 tw-h-24 tw-w-full tw-bg-neutral-200" data-testid="skeleton" />}
      </div>
      <div className="tw-sticky tw-bottom-0 tw-mt-4 tw-flex tw-w-full tw-justify-end tw-gap-2 tw-bg-white tw-py-4">
        <Button color="lightGray" type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="purple"
          type="button"
          onClick={createAndAddDeliverables}
          disabled={Object.keys(rowSelection).length === 0}
        >
          Create and add to project
        </Button>
      </div>
    </div>
  )
}

export default CreateDeliverablesTable
