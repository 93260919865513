import { baseRequest, BaseResponse } from '../api'
import { postApi } from '../api'

interface FileUrlResponse extends BaseResponse {
  data: {
    url: string
  }
}

export async function getPresignedUrl(filename: string, expires_in?: number): Promise<string> {
  const response = (await baseRequest('/api/internal/file_urls', postApi, {
    filename: filename,
    ...(expires_in && { expires_in }),
  })) as FileUrlResponse

  return response.data.url
}
